import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { EditButton, ListButton } from 'react-admin';
import { isNil } from 'ramda';
import { getDate, getVerboseDateTime } from '../../helpers/formatDate';
import { sizesDefinition } from './SizesList';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    '@media print': {
      '&>*': {
        pageBreakInside: 'avoid',
      },
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: spacing(0, 1),
    padding: spacing(),
  },
  border: {
    border: '1px solid black',
  },
  contractInfo: {
    gridTemplateAreas: '". . id" ". . id" ". . id"',
    gridTemplateColumns: '3fr 2fr 1fr',
    position: 'relative',
    [breakpoints.down('xs')]: {
      gridTemplateAreas: '"id" "." "."',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
    },
    '@media print': {
      gridTemplateAreas: '". . id" ". . id" ". . id"',
      gridTemplateColumns: '3fr 2fr 1fr',
    },
  },
  materialGrid: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 100px 100px',
    gap: spacing(0, 1),
  },
  materialSizeGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(9, 1fr)',
    gap: spacing(0, 1),
    marginBottom: spacing(1),
  },
  materialInfo: {
    padding: spacing(),
  },
  noteWrapper: {
    minHeight: 150,
    padding: spacing(),
  },
  footer: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr',
    borderCollapse: 'collapse',
  },
  footerBox: {
    flex: 1,
    border: '1px solid #000',
    padding: spacing(),
    borderWidth: '1px 1px 1px 0',
    '&:first-child': {
      borderWidth: 1,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  contractId: {
    fontSize: '34px',
    lineHeight: '1.5',
    gridArea: 'id',
    [breakpoints.down('md')]: {
      fontSize: '28px',
    },
  },
  dealerContractId: {
    marginTop: 'auto ',
  },
  title: {
    fontWeight: 'bold',
  },
}));

export const ContractShow = ({ record, basePath, resource }) => {
  const classes = useStyles();

  const translate = useTranslate();

  const { materials, dealer } = record;

  const dateCreated = useMemo(() => getVerboseDateTime(record.createdAt), [record]);
  const deadlineDate = useMemo(() => getDate(record.deadline_date), [record]);

  const hasSample = useMemo(() => materials?.some((material) => material?.number_of_samples), [materials]);

  const sortMaterials = useCallback((sizes, category) => {
    const orderedList = [];
    if (sizes) {
      const order = sizesDefinition[category];
      order.forEach((size) => {
        if (size in sizes && !isNil(sizes[size])) {
          orderedList.push([size.toUpperCase(), sizes[size]]);
        }
      });
    }
    return orderedList;
  }, []);

  return (
    <Box m={2} className={classes.root}>
      <Box display="flex" justifyContent="space-between" width="100%" className={'no-print'}>
        <EditButton basePath={basePath} record={record} resource={resource} size={'medium'} />
        <ListButton basePath={basePath} label="Zavřít" size={'medium'} />
      </Box>

      <Box className={classNames(classes.grid, classes.contractInfo)}>
        <Typography variant="h6">{translate('resources.contracts.show.title')}</Typography>
        <Typography className={classNames(classes.dealerContractId)}>{record.dealerContractId || ''}</Typography>
        <Typography align="right" className={classNames(classes.contractId)}>
          {record.id}
        </Typography>
        <Typography className={classes.title}>{translate('resources.contracts.fields.name')}</Typography>
        <Typography>{record.name}</Typography>
        <Typography className={classes.title}>{translate('resources.contracts.fields.created_at')}</Typography>
        <Typography>{dateCreated}</Typography>
        <Typography className={classes.title}>{translate('resources.contracts.fields.deadline_date')}</Typography>
        <Typography>{deadlineDate}</Typography>
      </Box>

      <Box className={classNames(classes.grid, classes.border)}>
        <Typography variant="h6">{translate('resources.contracts.show.customer_name')}</Typography>
        <Typography>{record.customer_name}</Typography>
        <Typography>{translate('resources.contracts.fields.dealer')}</Typography>
        <Typography>
          {dealer.username} - {dealer.email}
        </Typography>
      </Box>

      <Typography variant="h6" align="center">
        {translate('resources.contracts.show.production')}
      </Typography>

      <Box className={classNames(classes.grid, classes.border)}>
        <Typography variant="h6">{translate('resources.contracts.show.type_of_processing')}</Typography>
        <Typography>{record.processing?.name}</Typography>

        <Typography>{translate('resources.contracts.show.color_count')}</Typography>
        <Typography>{record.color_count}</Typography>
        <Typography>{translate('resources.contracts.show.save_print')}</Typography>
        <Typography>{record.save_print}</Typography>
      </Box>

      <Box className={classNames(classes.grid, classes.border)}>
        <Typography variant="h6">{translate('resources.contracts.show.sample')}</Typography>
        <Typography>{hasSample ? 'ANO' : 'NE'}</Typography>
      </Box>

      {hasSample && (
        <Box className={classNames(classes.border, classes.materialInfo)}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6">{translate('resources.contracts.show.material_sample')}</Typography>
          </Box>
          {materials &&
            materials
              .filter(Boolean)
              .filter((material) => Boolean(material.number_of_samples))
              .map((material) => (
                <React.Fragment key={material.data}>
                  <Box className={classes.materialGrid}>
                    <Typography>{material.data}</Typography>
                    <Typography>{material.color}</Typography>
                    <Typography>{material.size}</Typography>
                    <Typography>{material.number_of_samples}</Typography>
                  </Box>
                  <Divider />
                </React.Fragment>
              ))}
        </Box>
      )}

      <Box className={classNames(classes.materialInfo, classes.border)}>
        <Typography variant="h6">{translate('resources.contracts.show.material')}</Typography>
        <Divider />
        {materials &&
          materials.filter(Boolean).map((material) => (
            <React.Fragment key={material.data}>
              <Box className={classes.materialGrid}>
                <Typography className={classes.bold}>
                  {material.data} - {translate(`resources.contracts.show.sizeCategoryId.${material.sizeCategoryId}`)}
                </Typography>
                <Typography className={classes.bold}>{material.color}</Typography>
              </Box>

              <Box className={classes.materialSizeGrid}>
                {sortMaterials(material?.sizes, material.sizeCategoryId).map(([size, amount]) => (
                  <Typography key={size}>
                    {size}: {parseInt(amount)}
                  </Typography>
                ))}
              </Box>

              <Box className={classes.materialGrid}>
                <Typography>{material.size_note}</Typography>
              </Box>
            </React.Fragment>
          ))}
      </Box>

      <Box className={classNames(classes.noteWrapper, classes.border)}>
        <Typography variant="h6">{translate('resources.contracts.show.note')}</Typography>
        <Divider />
        <Typography>{record.description}</Typography>
      </Box>

      <Box className={classes.footer}>
        <div className={classes.footerBox}>
          <Typography variant="h6"> {translate('resources.contracts.show.processed')} </Typography>
          <Typography> {translate('resources.contracts.show.finished')}</Typography>
        </div>
        <div className={classes.footerBox}>
          <Typography variant="h6">{translate('resources.contracts.show.sieve')}</Typography>
        </div>
        <div className={classes.footerBox}>
          <Typography variant="h6">{translate('resources.contracts.show.wrap')}</Typography>
          <Typography>{record.packing}</Typography>
        </div>
        <div className={classes.footerBox}>
          <Typography variant="h6">{translate('resources.contracts.show.print')}</Typography>
        </div>
      </Box>
    </Box>
  );
};
