import { last, split } from 'ramda';
import * as Sentry from '@sentry/react';

/**
 * Normalizes different document formats into a unified structure
 * @param {Object|string} document - Document to normalize (Strapi entity, raw file or string path)
 * @returns {Object} Normalized document object
 */
export const normalizeDocument = (document) => {
  // Case 1: document is a string (path)
  if (typeof document === 'string') {
    const fileName = last(split('/', document));
    return {
      path: document,
      displayName: fileName,
      isLegacy: true,
    };
  }

  // Case 2: document is a Strapi entity with id and hash
  if (document && document.hash) {
    return {
      path: document.hash,
      displayName: document.hash,
      isLegacy: false,
      id: document.id || null,
    };
  }

  // Case 3: document is a raw file entity
  if (document && document.rawFile) {
    return {
      path: document.name,
      displayName: document.name,
      rawFile: document.rawFile,
      isLegacy: false,
    };
  }

  // Default fallback
  return {
    path: '',
    displayName: 'Unknown file',
    isLegacy: true,
  };
};

/**
 * Fetches a file from the server
 * @param {string} url - URL to fetch the file from
 * @returns {Promise<Response>} Fetch response
 */
export const getFile = (url) => {
  const token = localStorage.getItem('token');

  return fetch(url, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
};

/**
 * Downloads a file to the user's device
 * @param {Response} file - Fetch response containing the file
 * @param {string} name - Name of the file
 * @returns {Promise<void>}
 */
export const downloadFile = async (file, name) => {
  const blob = await file.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = last(split('/', name));
  document.body.appendChild(a);
  a.click();
  a.remove();
};

/**
 * Downloads a raw file directly from memory
 * @param {Blob|ArrayBuffer} rawFile - Raw file data
 * @param {string} fileName - Name to save the file as
 */
export const downloadRawFile = (rawFile, fileName) => {
  const blob = new Blob([rawFile]);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

/**
 * Handles document download with support for different document types
 * @param {Object} options - Download options
 * @param {Object} options.normalizedDoc - Normalized document object
 * @param {string} options.apiUrl - API base URL
 * @param {string|number} [options.id] - Document ID for new-style downloads
 * @param {Function} options.notify - Notification function
 * @returns {Promise<void>}
 */
export const handleDocumentDownload = async ({ normalizedDoc, apiUrl, id, notify }) => {
  try {
    let file;

    if (id) {
      // New way - using ID
      file = await getFile(`${apiUrl}/contract/download/${id}`);
    } else if (normalizedDoc.rawFile) {
      // Handle raw file case
      downloadRawFile(normalizedDoc.rawFile, normalizedDoc.displayName);
      return;
    } else {
      // Legacy way - using path
      const encodedPath = encodeURIComponent(normalizedDoc.path);
      file = await getFile(`${apiUrl}/contract/download?path=${encodedPath}`);
    }

    if (file?.ok) {
      downloadFile(file, normalizedDoc.path);
    } else {
      Sentry.addBreadcrumb({
        message: 'Requested file',
        type: 'NAS File',
        level: Sentry.Severity.Debug,
        data: { url: file.url, status: file.statusText },
      });
      throw new Error('File not found. NAS error');
    }
  } catch (error) {
    if (notify) {
      notify('resources.contracts.errors.fileNotFound', 'error');
      Sentry.captureMessage('File not found. NAS error', Sentry.Severity.Fatal);
    }
  }
};
