import * as React from 'react';
import { Datagrid, List, Pagination, ReferenceField, TextField, useGetIdentity } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import StatusCircle from '../components/StatusCircle';
import CustomIdWithStatus from '../components/CustomIdWIthStatus';
import ContractSize from '../components/ContractSize';
import ListActions from './components/ListActions';
import ContractFilter from './components/ContractFilter';

const useStyles = makeStyles(() => ({
  listRoot: {
    width: '100%',
  },

  // ugly hack to centered last 3 headers in table
  dataGrid: {
    '& .MuiTableHead-root .MuiTableRow-root': {
      '& th:nth-child(1n+6)': {
        textAlign: 'center',
      },
    },
  },
}));

const showOrEdit = (permissions) => (id, basePath, record) => {
  const isAdmin = permissions === 'authenticated';
  const isDesigner = permissions === 'designer';

  return isAdmin || isDesigner || record.contract_status === 'unresolved' ? 'edit' : 'show';
};

const PostPagination = (props) => <Pagination rowsPerPageOptions={[20, 50, 100]} {...props} />;

// eslint-disable-next-line
const ContractList = (props) => {
  const classes = useStyles();
  const { identity } = useGetIdentity();

  if (!props.permissions && !identity) {
    return null;
  }

  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={<ContractFilter />}
      filterDefaultValues={{ is_done: false }}
      exporter={false}
      bulkActionButtons={false}
      className={classes.listRoot}
      pagination={<PostPagination />}
      perPage={20}>
      <Datagrid rowClick={showOrEdit(props.permissions)} className={classes.dataGrid}>
        <CustomIdWithStatus source="deadline_date" statusSource="contract_status" />
        <TextField source="id" />
        {props.permissions === 'authenticated' && (
          <ReferenceField label="Obchodník" source="dealer.id" reference="users" link={false} sortable={false}>
            <TextField source="username" />
          </ReferenceField>
        )}
        <TextField source="name" />
        <ContractSize source="materials" label="Počet kusů" />
        <StatusCircle source="material_status" />
        <StatusCircle source="graphics_status" />
        <StatusCircle source="billing_status" />
      </Datagrid>
    </List>
  );
};

export default ContractList;
