// fork from https://github.com/nazirov91/ra-strapi-rest

import { has, isNil } from 'ramda';
import { CREATE, DELETE, fetchUtils, GET_LIST, GET_MANY_REFERENCE, GET_ONE, UPDATE } from 'react-admin';

export const SingleType = 'SingleType';

/**
 * Maps react-admin queries to a simple REST API
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
const dataProvider = (apiUrl, httpClient = fetchUtils.fetchJson, uploadFields = []) => {
  /**
   * Adjusts the query parameters for Strapi, including sorting, filtering, and pagination.
   * @param params - The input parameters containing pagination, sorting, filtering, target, and ID data.
   * @returns A query string for Strapi with the adjusted parameters.
   */
  /**
   * Adjusts the query parameters for Strapi, including sorting, filtering, and pagination.
   * @param {Object} params - The input parameters containing pagination, sorting, filtering, target, and ID data.
   * @returns {string} A query string for Strapi with the adjusted parameters.
   */
  const adjustQueryForStrapi = (params) => {
    // Handle SORTING
    const sortParam = buildSortParameter(params.sort);

    // Handle FILTER
    const filterParam = buildFilterParameter(params.filter, params);

    // Handle PAGINATION
    const paginationParam = buildPaginationParameter(params.pagination);

    // Combine all parameters into a single query string
    return `${sortParam}&${paginationParam}&${filterParam}`;
  };

  /**
   * Builds the sort parameter for Strapi query
   * @param {Object} sort - The sort object containing field and order
   * @returns {string} Formatted sort parameter
   */
  const buildSortParameter = (sort) => {
    return sort.field === '' ? 'sort=updated_at:desc' : `sort[${sort.field}]=${sort.order.toLowerCase()}`;
  };

  /**
   * Builds the filter parameter for Strapi query
   * @param {Object} filter - The filter object
   * @param {Object} params - The full params object (for target/id reference)
   * @returns {string} Formatted filter parameter
   */
  const buildFilterParameter = (filter, params) => {
    const filterParts = [];

    // Process each filter key
    Object.entries(filter).forEach(([key, value]) => {
      const filterPart = buildFilterPartForKey(key, value, filter);
      if (filterPart) filterParts.push(filterPart);
    });

    // Handle reference filtering (for relationships)
    if (params.id && params.target && params.target.indexOf('_id') !== -1) {
      const target = params.target.substring(0, params.target.length - 3);
      filterParts.push(`filters[${target}][$eq]=${params.id}`);
    }

    return filterParts.join('&');
  };

  /**
   * Builds a filter part for a specific key
   * @param {string} key - The filter key
   * @param {any} value - The filter value
   * @param {Object} filter - The full filter object (for context)
   * @returns {string} Formatted filter part
   */
  const buildFilterPartForKey = (key, value, filter) => {
    switch (key) {
      case 'is_done':
        return `filters[contract_status][$${value === true ? 'eq' : 'ne'}]=done`;

      case 'dealer':
        return `filters[dealer][id][$eq]=${value}`;

      case 'deadline_date_gte':
        return `filters[deadline_date][$gte]=${value}`;

      case 'deadline_date_lte':
        return `filters[deadline_date][$lte]=${value}`;

      case 'q':
        if (value === '') return '';
        // Strapi v4 doesn't have a global _q search parameter
        // We need to specify which fields to search with $containsi wrapped in $or
        const searchableFields = ['name', 'description', 'reference_number', 'note', 'id'];

        // Create filters wrapped in $or operator for each searchable field
        return searchableFields
          .map((field, index) => `filters[$or][${index}][${field}][$containsi]=${value}`)
          .join('&');

      default:
        return `filters[${key}][$eq]=${value}`;
    }
  };

  /**
   * Builds the pagination parameter for Strapi query
   * @param {Object} pagination - The pagination object containing page and perPage
   * @returns {string} Formatted pagination parameter
   */
  const buildPaginationParameter = (pagination) => {
    const { page, perPage } = pagination;
    const start = (page - 1) * perPage;
    return `pagination[start]=${start}&pagination[limit]=${perPage}`;
  };

  const getUploadFieldNames = (data) => {
    if (!data || typeof data !== 'object') return [];
    const hasRawFile = (value) => {
      return (
        value &&
        typeof value === 'object' &&
        ('rawFile' in value ||
          (Array.isArray(value) && value.some(hasRawFile)) ||
          Object.values(value).some(hasRawFile))
      );
    };

    return Object.keys(data).filter((key) => hasRawFile(data[key]));
  };

  /**
   * Handles file uploads and data updates for a given resource.
   * @param type - The operation type, either UPDATE or a different value for creating new resources.
   * @param resource - The target resource for the file upload or data update.
   * @param params - The parameters containing the data to be uploaded or updated.
   * @returns The processed response from the server, converted to the appropriate format.
   */
  const handleFileUpload = async (type, resource, params) => {
    const id = type === UPDATE ? `/${params.id}` : '';
    const url = `${apiUrl}/${resource}${params.id === SingleType ? '' : id}`;
    const requestMethod = type === UPDATE ? 'PUT' : 'POST';
    const formData = new FormData();
    const uploadFieldNames = getUploadFieldNames(params.data);

    const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data;

    uploadFieldNames.forEach((fieldName) => {
      const fieldData = Array.isArray(params.data[fieldName]) ? params.data[fieldName] : [params.data[fieldName]];
      data[fieldName] = fieldData.reduce((acc, item) => {
        //clean empty files - where are they coming from?
        item.rawFile instanceof File
          ? formData.append(`files.${fieldName}`, item.rawFile) //add new files
          : acc.push(item.id); //reference existing files
        return acc;
      }, []);
    });
    formData.append('data', JSON.stringify(data));

    const response = await processRequest(url, { method: requestMethod, body: formData });
    return convertHTTPResponse(response, type, params);
  };

  /**
   * Recursively flattens a Strapi API response.
   * It removes the `attributes` (and single-key `data`) wrapper,
   * ensuring that the `id` is preserved at each level.
   *
   * @param data - The input data from Strapi.
   * @returns The flattened data.
   */
  const flattenStrapiResponse = (data) => {
    // If the data is an array, process each element.
    if (Array.isArray(data)) {
      return data.map(flattenStrapiResponse);
    }

    // If the data is an object, process it.
    if (data !== null && typeof data === 'object') {
      const keys = Object.keys(data);

      // If the object only has a "data" property, flatten that.
      if (keys.length === 1 && keys[0] === 'data') {
        return flattenStrapiResponse(data.data);
      }

      // If the object has an "attributes" property, merge its properties with the id.
      if (data.hasOwnProperty('attributes')) {
        const { id, attributes } = data;
        return { id, ...flattenStrapiResponse(attributes) };
      }

      // Otherwise, recursively process each property.
      return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, flattenStrapiResponse(value)]));
    }

    // For primitives (string, number, etc.), return the value as is.
    return data;
  };

  const convertHTTPResponse = (response, type, params) => {
    //TODO - different format for incomig and outcomming data :facepalm:
    const { json } = response;
    const raData = isNil(json.data) ? json : flattenStrapiResponse(json.data);
    switch (type) {
      case GET_ONE:
        return { data: raData };
      case GET_LIST:
      case GET_MANY_REFERENCE:
        return {
          data: raData,
          total: json.meta.pagination.total,
        };
      case CREATE:
        return { data: { ...params.data, id: raData.id } };
      case DELETE:
        return { data: { id: null } };
      default:
        return { data: raData };
    }
  };

  const processRequest = async (url, options = {}) => {
    //url contains string api/users then add populate="role"
    if (url.includes('api/users')) {
      const separator = url.includes('?') ? '&' : '?';
      return httpClient(`${url}${separator}populate=role`.replace('&&', '&'), options);
    }

    if (url.includes('api/contracts')) {
      const populate = ['processing', 'dealer', 'files', 'production_files', 'film_files'];

      const newUrl = populate.reduce((acc, item) => {
        const separator = acc.includes('?') ? '&' : '?';
        return `${acc}${separator}populate=${item}`;
      }, url);

      return httpClient(newUrl, options);
    }

    return httpClient(url, options);
  };

  return {
    getList: async (resource, params) => {
      const url = `${apiUrl}/${resource}?${adjustQueryForStrapi(params)}`;
      const res = await processRequest(url, {});
      if (res.json.meta) {
        return convertHTTPResponse(res, GET_LIST, params);
      }
      const newRes = {
        ...res,
        json: {
          data: res.json,
          meta: {
            pagination: {
              total: res.json.length,
            },
          },
        },
      };

      return convertHTTPResponse(newRes, GET_LIST, params);
    },

    getOne: async (resource, params) => {
      const isSingleType = params.id === SingleType;
      const url = `${apiUrl}/${resource}${isSingleType ? '' : '/' + params.id}`;
      const res = await processRequest(url, {});
      return convertHTTPResponse(res, GET_ONE, params);
    },

    getMany: async (resource, params) => {
      if (params.ids.length === 0) return { data: [] };
      const ids = params.ids.filter((i) => !(typeof i === 'object' && i.hasOwnProperty('data') && i.data === null));

      const responses = await Promise.all(
        ids.map((i) => {
          return processRequest(`${apiUrl}/${resource}/${i.id || i._id || i}`, {
            method: 'GET',
          });
        })
      );
      return {
        data: responses.map((response) =>
          flattenStrapiResponse(has('data', response.json) ? response.json.data : response.json)
        ),
      };
    },

    getManyReference: async (resource, params) => {
      const url = `${apiUrl}/${resource}?${adjustQueryForStrapi(params)}`;
      const res = await processRequest(url, {});
      return convertHTTPResponse(res, GET_MANY_REFERENCE, params);
    },

    update: async (resource, params) => {
      if (getUploadFieldNames(params.data).length > 0) return await handleFileUpload(UPDATE, resource, params);

      const isSingleType = params.id === SingleType;
      const url = `${apiUrl}/${resource}${isSingleType ? '' : '/' + params.id}`;
      const options = {};
      options.method = 'PUT';
      // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
      const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data;
      options.body = JSON.stringify({ data });

      const res = await processRequest(url, options);
      return convertHTTPResponse(res, UPDATE, params);
    },

    updateMany: async (resource, params) => {
      const responses = await Promise.all(
        params.ids.map((id) => {
          // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
          const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data;
          return processRequest(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
          });
        })
      );
      return {
        data: responses.map((response) => flattenStrapiResponse(response.json.data)),
      };
    },

    create: async (resource, params) => {
      if (getUploadFieldNames(params.data).length > 0) return await handleFileUpload(CREATE, resource, params);

      const url = `${apiUrl}/${resource}`;
      const res = await processRequest(url, {
        method: 'POST',
        body: JSON.stringify(params),
      });
      return convertHTTPResponse(res, CREATE, { data: params.data });
    },

    delete: async (resource, { id }) => {
      const url = `${apiUrl}/${resource}${id === SingleType ? '' : `/${id}`}`;
      const res = await processRequest(url, { method: 'DELETE' });
      return convertHTTPResponse(res, DELETE, { id });
    },

    deleteMany: async (resource, { ids }) => {
      const data = await Promise.all(
        ids.map(async (id) => {
          const response = await processRequest(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          });
          return flattenStrapiResponse(response?.json.data);
        })
      );
      return { data };
    },
  };
};

export default dataProvider;
