// TODO
// import * as Sentry from '@sentry/react';
import { isNil } from 'ramda';

export const LS_TOKEN = 'token';
export const LS_ROLE = 'role';
export const LS_AUTH = 'auth';

const authProvider = ({ REACT_APP_API_URL }) => ({
  // Called when the user attempts to log in
  login: async ({ username, password }) => {
    const request = new Request(`${REACT_APP_API_URL}/auth/local`, {
      method: 'POST',
      body: JSON.stringify({ identifier: username, password }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const response = await fetch(request);
    if (!response.ok) {
      // You can customize error handling here (e.g., show a notification)
      throw new Error('Network error: Unable to login');
    }
    const { jwt, user } = await response.json();
    // Store the token in localStorage (or another storage mechanism)
    localStorage.setItem(LS_TOKEN, jwt);
    localStorage.setItem(LS_AUTH, JSON.stringify(user));

    // Step 2: Use the JWT to fetch full user details (including role)
    const meRequest = new Request(`${REACT_APP_API_URL}/user/me`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      }),
    });
    const meResponse = await fetch(meRequest);
    if (!meResponse.ok) {
      throw new Error('Network error: Unable to fetch user data');
    }
    const userData = await meResponse.json();

    const role = userData?.role?.type;

    if (isNil(role)) {
      localStorage.removeItem(LS_TOKEN);
      localStorage.removeItem(LS_AUTH);
      throw new Error('Network error: Unable to fetch');
    }

    localStorage.setItem(LS_ROLE, role);

    return Promise.resolve();
  },

  // Called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem(LS_TOKEN);
    return Promise.resolve();
  },

  // Called when the API returns an error (e.g., 401, 403) to check if it should logout the user
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(LS_TOKEN);
      return Promise.reject();
    }
    return Promise.resolve();
  },

  // Called when the user navigates to a new location to check for authentication
  checkAuth: () => (localStorage.getItem(LS_TOKEN) ? Promise.resolve() : Promise.reject()),

  // Called when the user navigates to a new location to check for permissions/roles
  // Adjust this if you have role-based permissions in your Strapi app
  getPermissions: () => {
    const role = localStorage.getItem(LS_ROLE);
    return role ? Promise.resolve(role) : Promise.reject(new Error('ErrorGetPermissions'));
  },
  getIdentity: () => {
    try {
      const { id, username, email } = JSON.parse(localStorage.getItem(LS_AUTH));
      return Promise.resolve({ id, username, email });
    } catch (error) {
      return Promise.reject(error);
    }
  },
});

export default authProvider;
