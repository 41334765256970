import React from 'react';
import { usePermissions } from 'ra-core';
import { NasRow } from './NasRow';

export const NasList = ({ graphics, production, films, oldWay }) => {
  const { permissions } = usePermissions();
  const isAdmin = permissions === 'authenticated';

  return (
    <>
      <NasRow name="graphics" documents={graphics} oldDocuments={oldWay?.files} />
      {isAdmin && <NasRow name="production" documents={production} oldDocuments={oldWay?.production_files} />}
      <NasRow name="films" documents={films} oldDocuments={oldWay?.film_files} />
    </>
  );
};
