import { ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import React, { useContext } from 'react';
import { useNotify } from 'ra-core';
import { AppConfigContext } from '../../config';
import { normalizeDocument, handleDocumentDownload } from '../../helpers/documents';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  listItemText: {
    overflowWrap: 'anywhere',
  },
}));

export const NasItem = ({ document, id }) => {
  const classes = useStyles();
  const { REACT_APP_API_URL } = useContext(AppConfigContext);
  const notify = useNotify();

  const normalizedDoc = normalizeDocument(document);

  const handleFileDownload = () => {
    handleDocumentDownload({
      normalizedDoc,
      apiUrl: REACT_APP_API_URL,
      id,
      notify,
    });
  };

  return (
    <ListItem button onClick={handleFileDownload}>
      <ListItemIcon>
        <ImageIcon />
      </ListItemIcon>
      <ListItemText className={classes.listItemText} primary={normalizedDoc.displayName} />
    </ListItem>
  );
};
