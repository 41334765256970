import {
  any,
  assoc,
  assocPath,
  complement,
  dissoc,
  either,
  endsWith,
  filter,
  fromPairs,
  has,
  isNil,
  join,
  keys,
  length,
  map,
  nth,
  path,
  pickBy,
  pipe,
  prop,
  propIs,
  props,
  propSatisfies,
  replace,
  toPairs,
  unless,
  when,
} from 'ramda';

/**
 * Input: Material row
 */
const concatMaterialValues = pipe(props(['data', 'size', 'color', 'count', 'number_of_samples']), join(', '));

/**
 * Input: Diff Object
 * output: simplified material value
 * @type {*|(function(*=, *=): (*))|(function(*=): (*))}
 */
const mergeMaterialValues = pipe(path(['materials', '__new']), map(concatMaterialValues));

export const transformFirstMaterials = (diff) =>
  pipe(
    assocPath(['firstMaterials', '__new'], mergeMaterialValues(diff)),
    assocPath(['firstMaterials', '__old'], null)
  )(diff);

const hasValues = (propName, valueFilter) =>
  pipe(
    prop(propName),
    filter((value) => value[0] === valueFilter),
    length
  );

const getMaterials = (diff, materialFilter) =>
  pipe(
    prop('materials'),
    filter((material) => material[0] === materialFilter),
    map(nth(1)),
    map(concatMaterialValues)
  )(diff);

const getFiles = (fileType) => (diff, valueFilter) =>
  pipe(
    prop(fileType),
    filter((file) => file[0] === valueFilter),
    map(nth(1)),
    map(prop('name'))
  )(diff);

export const transformAddMaterials = (diff) =>
  when(
    hasValues('materials', '+'),
    pipe(assocPath(['addedMaterials', '__new'], getMaterials(diff, '+')), assocPath(['addedMaterials', '__old'], null))
  )(diff);

export const transformDeletedMaterials = (diff) =>
  when(
    hasValues('materials', '-'),
    pipe(
      assocPath(['deletedMaterials', '__old'], getMaterials(diff, '-')),
      assocPath(['deletedMaterials', '__new'], null)
    )
  )(diff);

export const transformDealer = (diff) => {
  return assoc('dealer', diff.dealer.username, diff);
};

export const transformAddedFiles = (fileType) => (diff) =>
  when(
    hasValues(fileType, '+'),
    pipe(
      assocPath([`${fileType}Added`, '__new'], getFiles(fileType)(diff, '+')),
      assocPath([`${fileType}Added`, '__old'], null)
    )
  )(diff);

export const transformDeletedFiles = (fileType) => (diff) =>
  when(
    hasValues(fileType, '-'),
    pipe(
      assocPath([`${fileType}Deleted`, '__new'], null),
      assocPath([`${fileType}Deleted`, '__old'], getFiles(fileType)(diff, '-'))
    )
  )(diff);

const unifyDataFormat = pipe(
  toPairs,
  map(([key, value]) => {
    if (endsWith('__added', key)) {
      return [replace(/__added$/, '', key), { __new: value, __old: null }];
    } else if (endsWith('__deleted', key)) {
      return [replace(/__deleted$/, '', key), { __old: value, __new: null }];
    }
    return [key, value];
  }),
  fromPairs
);

export const cleanObject = (obj) => {
  return pickBy(complement(isNil), obj);
};

export const transformAudit = (diff) => {
  if (!diff) {
    return null;
  }

  const omitMetaData = pipe(dissoc('id'), dissoc('createdAt'), dissoc('updatedAt'), dissoc('author'));
  const omitBuggedData = pipe(dissoc('files'), dissoc('film_files'), dissoc('production_files'));

  // todo - transformation functions could be transfered to audit service and transform data on new audit
  const transformData = pipe(
    when(
      has('materials'),
      pipe(
        unless(propIs(Array, 'materials'), transformFirstMaterials),
        when(propIs(Array, 'materials'), transformAddMaterials),
        when(propIs(Array, 'materials'), transformDeletedMaterials),
        dissoc('materials')
      )
    ),
    when(has('dealer'), transformDealer)
    // when(has('files'), pipe(transformAddedFiles('files'), transformDeletedFiles('files'), dissoc('files'))),
    // when(
    //   has('production_files'),
    //   pipe(
    //     transformAddedFiles('production_files'),
    //     transformDeletedFiles('production_files'),
    //     dissoc('production_files'),
    //   ),
    // ),
    // when(
    //   has('film_files'),
    //   pipe(transformAddedFiles('film_files'), transformDeletedFiles('film_files'), dissoc('film_files')),
    // ),
  );

  const addActions = map(
    pipe(
      assoc('action', 'resources.audits.type.updated'), // default: updated
      when(
        either(propSatisfies(isNil, '__old'), pipe(keys, any(endsWith('__added')))),
        assoc('action', 'resources.audits.type.added') // Created: either __old is nil or an __added prop exists
      ),
      when(
        either(propSatisfies(isNil, '__new'), pipe(keys, any(endsWith('__deleted')))),
        assoc('action', 'resources.audits.type.deleted') // Removed: either __new is nil or a __deleted prop exists
      )
    )
  );

  return pipe(omitMetaData, cleanObject, unifyDataFormat, omitBuggedData, transformData, addActions)(diff);
};
