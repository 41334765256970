import { Accordion, AccordionDetails, AccordionSummary, List, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslate } from 'ra-core';
import React from 'react';
import { NasItem } from './NasItem';

export const NasRow = ({ name, documents = [], oldDocuments = [] }) => {
  const translate = useTranslate();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{translate(`resources.nasDocuments.list.${name}`)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {/* {documents?.map((document) => (
            <NasItem key={document.name} document={document} id={document.id} />
          ))} */}
          {oldDocuments?.map((document) => {
            const name = document.split('/').pop();
            return <NasItem key={name} document={document} />;
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
