import React from 'react';
import { useTranslate } from 'ra-core';
import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import { is } from 'ramda';
import { transformAudit } from '../../helpers/dataProcessing';
import { getDateTime } from '../../helpers/formatDate';

const useStyles = makeStyles(() => ({
  root: {},
  info: {
    fontWeight: 'bold',
  },
  oldValue: {
    textDecoration: 'line-through',
    margin: 0,
  },
  newValue: {
    margin: 0,
    overflowWrap: 'anywhere',
  },
  text: {},
  item: {
    fontWeight: 'bold',
  },
}));

const PrintValue = ({ value, className }) => {
  if (is(Array, value)) {
    return (
      <ul className={className}>
        {value.map((row) => (
          <Typography component="li" key={row}>
            {row}
          </Typography>
        ))}
      </ul>
    );
  }
  if (is(Object, value)) {
    // todo - processing
    return null;
  }
  return (
    <Typography component="span" className={className}>
      {value}
    </Typography>
  );
};

export const AuditRow = ({ audit, authorInfo }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const diff = transformAudit(audit.diff);

  return (
    <Box m={2}>
      <Typography className={classes.info}>
        {getDateTime(audit?.createdAt)} - {authorInfo?.username}
      </Typography>

      {diff &&
        Object.entries(diff).map(([key, { action, __old, __new }]) => (
          <Box key={key}>
            <Typography component="span" className={classes.text}>
              {translate(action)}{' '}
            </Typography>
            <Typography component="span" className={classes.item}>
              {translate(`resources.contracts.fields.${key}`)}:
            </Typography>
            <PrintValue value={__old} className={classes.oldValue} />
            {__old && __new && <Typography component="span"> -&gt; </Typography>}
            <PrintValue value={__new} className={classes.newValue} />
          </Box>
        ))}

      {!diff && <Typography>{translate('resources.audits.messages.contractCreated')} </Typography>}

      <Divider />
    </Box>
  );
};
